<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="">
                        <div class="">
                          <div class="row">
                            <div class="col-xl-12">
                              <div class="product-detail">
                                <div class="row">
                                  <div class="col-md-12 col-2">
                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product1"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1551407961_PGB-730.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-3">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="width: 120px; height: 60px"
                                        class="nav-link"
                                        @click="wehed()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            border-style: solid;
                                            border-width: 1px;
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1551407961_PGB-730.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- end product img -->
                            </div>
                          </div>
                          <!-- end row -->
                        </div>
                      </div>
                      <!-- end card -->
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="mt-1 col-11">
                    <div class="row">
                      <h1 class="font-size-48 mb-3">
                        <strong>
                          Intelligent Glue Potting Machine PGB-730
                        </strong>
                      </h1>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Brand :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >D&H</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Model :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >PGB-730</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Applications :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >Capacitor, power supply, transformers, ignition coil,
                          ignition coil, ballast and other products which need
                          to be glued</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Suitable Material :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >One-component glue or already mixed glue ,Solder
                          Paste, Epoxy, Silicone, PU, Black Glue, White Glue,
                          etc</span
                        >
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-6">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 450px; height: 250px"
                            title="YouToube Video"
                            src="https://www.youtube.com/embed/uZpUH1FAhx0"
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <h1 style="color: black; font-weight: 700">Features</h1>
                        <br />
                        <div class="row" style="font-size: 14px">
                          1、Intelligent meter , remote operation
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          2、Flexible manufacturing system
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          3、Real time monitoring
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          4、Accessible MES system
                        </div>
                        <br />
                      </div>
                      <!-- end col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <br /><br />

            <br />
            <b-tabs pills card style="font-size: 18px">
              <b-tab active title="Technical Data">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <ul class="container">
                      <li>Dimension:1650*1250*1700mm</li>
                      <li>XYZ working area: 700*700*100mm</li>
                      <li>AB ratio: 100:100-100:10 adjustable</li>
                      <li>Movement Track: Dot/Line/Arc/Circle</li>
                      <li>
                        Speed: 500mm/s (Movement speed) 1-12g/s (gluing out
                        speed)
                      </li>
                      <li>
                        Accuracy: ±0.03mm (Repeatability) ±2% (potting) ±2%
                        (mixing)
                      </li>
                      <li>Program: 99pcs/more</li>
                      <li>Input pressure: 0.5MPa-0.7MPa</li>
                      <li>
                        Viscosity: 50cps-30000cps (Glue can be heated to
                        decrease viscosity)
                      </li>
                      <li>Operation Method: Manual/Automatic</li>
                      <li>
                        Meter Pump: Gear pump/Screw pump/Double cylinder plunger
                        pump
                      </li>
                      <li>Flow Control: Digital Input</li>
                      <li>Programming method: teaching programming</li>
                      <li>
                        Automated control system: PLC / touch screen
                        (programming, modification, storage, etc.)
                      </li>
                      <li>
                        Glue control system: AB glue suction valve to achieve
                        independent operation of AB glue
                      </li>
                      <li>
                        Mixed mode: dynamic and static optional, instant mixing,
                        easy to clean
                      </li>
                      <li>
                        Tank：stainless steel(40L)、(25L)、（10L）optional
                      </li>
                      <li>
                        Power supply: 220V/50Hz/1200W ( The power consumption
                        without heater)
                      </li>
                    </ul>
                  </div>
                </div>
              </b-tab>
              <b-tab title="The Working Principle">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    Liquid A & B are kept in two tanks separately. There are two
                    sets of metering pumps inside the machine, draining out
                    liquids individually from tank A & B. Liquid A & B mix with
                    each other in the mixer pipe and dispensed out according to
                    the programmable data.
                    <img
                      src="https://v4-upload.goalsites.com/141/editor_1529630830_%E5%9B%BE%E7%89%871.png"
                      alt="img-1"
                      class="img-fluid mx-auto d-block rounded"
                    />
                  </div>
                </div>
              </b-tab>
            </b-tabs>

            <br /><br />
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  data() {
    return {
      product1: true,
    };
  },
  components: {
    Header,

    Footer,
  },
  methods: {
    wehed() {
      (this.product1 = true),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = false);
    },

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>